<template>
  <div>
<!--    <van-search-->
<!--        v-model="Search.Title"-->
<!--        show-action-->
<!--        placeholder="请输入标题"-->
<!--        @search="onSearch"-->
<!--        @cancel="onCancel"-->
<!--    />-->
    <van-list>
      <div>
        <div v-if="flag === true">
          <div class="weui-cells" style="margin-top: 10px;" v-for="(item, i) in list" :key="i" >
            <a class="weui-cell weui-cell_access" href="javascript:;" @click="toInfo(item.ID)">
              <div class="weui-media-box__hd">
                <img src="../../../assets/images/logo_2.jpg" alt="" style="width:30px;margin-right:10px;">
              </div>
              <div class="weui-cell__bd">
                <p><span  class="bt">{{item.Title}}</span><br><span>上报时间：{{item.Date_OP}}</span></p>
              </div>
              <div class="weui-cell__ft"></div>
            </a>
          </div>
        </div>
        <van-empty description="无数据，请重新查询" v-else/>
      </div>
    </van-list>
  </div>
</template>

<script>
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import DES from "@/assets/js/DES";
import Emas from "@/assets/js/Emas";

export default {
name: "yhtsList",
  data() {
    return {
      list: [],
      type: '',
      value: '',
      flag: true,
      loading: false,   //是否处于加载状态
      finished: false,  //是否已加载完所有数据
      isFinished: false,
      pageCount:'',  //总页数
      token:'',
      openid:'',
      Info:''
    };
  },
  created() {
    this.token = sessionStorage.getItem("token");
    this.openid=sessionStorage.getItem("Openid");;
    this.fetchData();
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
    async fetchData() {
// let a='{"result":"ok","msg":{"rows":[{"ID":"22","Type":"暂未处理","Title":"投诉标题","Date_OP":"2021-11-17 02:32:58"},{"ID":"21","Type":"暂未处理","Title":"投诉标题","Date_OP":"2021-11-17 02:08:41"}]}}';
// let data=JSON.parse(a);
//       console.log(a)
//       console.log(data)
//       console.log(data.msg.rows)
//       if (data.result=="ok"){
//   this.list=data.msg.rows
// }
// return;
      // if (data.code === 200) {
      //   this.flag = true
      //   this.pageCount = data.pageCount
      //   this.list = this.list.concat(data.data);
      //   console.log(this.pageCount)
      //   this.loading = false
      // } else {
      //   this.flag = false
      //   this.loading = false; //不处于加载状态
      //   this.isFinished = true;   //数据加载完，不会再处于加载状态
      // }
      let strData='{"openid":"' + this.openid +'"}';
      // console.log(strData)
      // alert(strData)
      this.Info=DES.encryptDes(strData)
      console.log(this.Info)
      mgop({
        api: "mgop.will.mqsb.SjYhTs", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'3', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          // alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {
            if (data.data.msg.rows.length>0){
              this.flag=true
              this.list=data.data.msg.rows
            }else {
              this.flag=false
            }
              // this.list=data.data.msg.rows
              // this.flag=true
          }else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          } else {
              this.flag=false
          }
        },
        onFail: (err) => {
          // alert(JSON.stringify(err))
          console.log("mgop run error. error :  " + JSON.stringify(err));
          Toast.fail('获取数据失败，请重试');
          this.flag=false
        },
      });
    },toInfo(ID) {
      if(ID !== "") {
        this.$router.push({
          path: '/yhtsInfo',
          query: {
            ID: ID,
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>